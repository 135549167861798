<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in LeadDetail")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === 'yes'")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === 'yes'")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from '../../../components/TheNavbar';
import Footer from '../../../components/TheFooter';
import BackHelp from "../../../components/BackHelp";
export default {
  name: "LeadDetail",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      search: "",
      LeadDetail: [
        {
          title: "2.4 Lead Details",
          description: [
            {
              text: "On the client details screen, you can access all the information and actions related to a client or lead. From here you can view and edit your follow up date, groups, notes, and interaction timeline, contact the client, and edit or delete the client.",
              imgType: "yes",
              img: "lead details.png",
            },
            {
              text: "You can call, SMS, WhatsApp, email the client using the buttons at the top of the screen. If the relevant information isn't provided, the contact buttons will be greyed out.",
              imgType: "no",
            },
          ],
          explanation: [
            {
              expTitle: "Notes:",
              expDesc: "Notes are for you to remember details about the contact, such as their preferences. These are private to you, and never shown to your clients. This field is optional.",
            },
            {
              expTitle: "Lead status:",
              expDesc: "The status of this lead. A newly added contact will be marked as \"New\" for 30 days by default. If there are activities between you and the lead, the status will change to \"In progress\". If the deal has been closed, you can change the status to \"Closed\"",
            },
            {
              expTitle: "Group:",
              expDesc: "This section displays all custom groups the client is a part of. Tap “Add new group”  to add the client to the group. This section will be empty if you haven’t created any groups yet.",
            },
            {
              expTitle: "Timeline:",
              expDesc: "Provides you with a detailed history of each interaction with the client. Kloser tracks activities (e.g. phone calls, messages, meetings, and notes) and content sent to clients.",
            },
            {
              expTitle: "Filter:",
              expDesc: "You can use a filter to view a particular activity. Tap on “Filter”, check the checkbox on the selected items, tap on “Apply filter”",
              imgType: "yes",
              img: "53.Contact info_filter.png",
            },
            {
              expTitle: "Date selection:",
              expDesc: "You can use a filter to view activity on selected dates. Tap on the \"Dates\" button, select a date or a range of dates, and then click \"Apply date filter\".",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
